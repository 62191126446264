<template>
    <div class="page-wrap">
        <div class="session-form-hold">
            <base-card>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="primary"
                />
                <v-card-text class="text-center">
                    <img
                        src="@/assets/img/logo-default.png"
                        alt=""
                        width="150px"
                    >
                    <p class="text--disabled font-weight-medium mb-6 mt-4">
                        {{ $t('users.forgot.recoveryPass') }}
                    </p>
                    <span
                        v-if="serverError"
                        class="red--text mb-2"
                    >
                        {{ serverError }}
                    </span>
                    <v-form
                        ref="form"
                        v-model="isValid"
                    >
                        <v-text-field
                            v-model="email"
                            :label="$t('users.login.email')"
                            validate-on-blur
                            :rules="[rules.required, rules.validEmail]"
                            @keyup="serverError = ''"
                        />
                    </v-form>

                    <!--                  :style="`opacity: ${(!isValid && !serverCheck) ? '0.2' : '1'}`"-->
                    <v-btn
                        class="mb-4"
                        block
                        color="primary"
                        dark
                        @click="submit"
                    >
                        {{ $t('users.forgot.recoverySubmit') }}
                    </v-btn>
                    <div style="display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap;">
                        <v-btn
                            text
                            color="primary"
                            to="/app/sessions/sign-in-two"
                        >
                            {{ $t('users.login.signIn') }}
                        </v-btn>

                        <language-selector />
                        <!--                        <v-btn
                            text
                            small
                            color="primary"
                        >
                            Create New Account
                        </v-btn>-->
                    </div>
                </v-card-text>
            </base-card>
        </div>
        <confirm-dialog
            ref="confirm"
        />
    </div>
</template>
<script>
import rules from '@/helpers/rulesValidate'
import axios from '@/plugins/axios'
import { mapGetters } from "vuex";
import LanguageSelector from "@/components/common/LanguageSelector";
import ConfirmDialog from "@/components/common/ConfirmDialog";

export default {
    name: 'Forgot',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Forgot'
    },
    components: { LanguageSelector, ConfirmDialog },
    data() {
        return {
            isValid: true,
            serverCheck: false,
            serverError: '',
            email: '',
            loading: false,
            rules: {
                ...rules
            }
        }
    },
    computed: {
        ...mapGetters("languages", ['getLang'])
    },
    methods: {
        async submit() {
            if (!this.$refs.form.validate()) return false;

            this.loading = true;

            try {
                const result = await axios
                    .post("/recoveryPassword", {
                        email: this.email.trim(),
                        lang: this.getLang
                    });

                if (result.status === 202) {
                    // this.$store.dispatch('snackbar/success', this.$t('users.forgot.checkEmail'));
                    // this.$router.push('/app/sessions/sign-in-two')

                    if (
                        await this.$refs.confirm.open(
                            this.$t('common.infoTitle'),
                            this.$t('users.forgot.checkEmail'),
                            { noCancel: true }
                        )
                    ) {
                        this.loading = false;
                        this.$router.push('/app/sessions/sign-in-two');
                    }
                } else this.loading = false;
            } catch (error) {
                /* console.log('error *******')
                console.log(error.response) */
                this.loading = false;
                if (error.response.status === 406) {
                    this.serverError = this.$t('users.forgot.emailNotFound');
                } else if (error.response.status === 422) {
                    // var errors = error.response.data.errors;
                }
            }
        /* setTimeout(() => {
            console.log('done')
        }, 1000) */
        }
    }
}
</script>
<style lang="scss" scoped>
.page-wrap {
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
